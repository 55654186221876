<template>
  <ori-card-wrap
    class="cu-card"
    zhTitle="人才库"
    enTitle="Talent pool"
    desc="链接高层次创新创业人才"
  >
    <span slot="more">查看更多></span>
    <template>
      <ul class="cu-card-list">
        <li v-for="(i, index) in cuData" :key="i.id" v-show="index < 8">
          <div class="info-wrap">
            <div class="avatar">
              <img :src="i.avatar" alt="" srcset="" />
            </div>
            <div class="info">
              <div class="cname hover-red">
                {{ i.cname }}
              </div>
              <div class="name">
                {{ i.name }}
              </div>
              <div class="look-time">
                <span>
                  <i class="el-icon-view"></i>
                </span>
                <span>{{ i.lookTime }}</span>
              </div>
              <div class="xl-tag">
                <span>学位：</span>
                <span>{{ i.xlTag }}</span>
              </div>
            </div>
          </div>
          <div class="tag-wrap">
            <div class="solo-row row">
              <span class="label">任职模式：</span>
              <span>{{ i.msTag.slice(0, 3).join("、") }}</span>
            </div>
            <div class="solo-row row">
              <span class="label">专业领域：</span>
              <span>{{ i.hyTag }}</span>
            </div>
          </div>
        </li>
      </ul>
    </template>
  </ori-card-wrap>
</template>

<script>
import { cuCardData } from "@/Mock";
export default {
  name: "cu-card",
  data() {
    return {
      cuData: cuCardData.list,
    };
  },
};
</script>

<style lang="scss" scoped>
.cu-card {
  .cu-card-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      width: 300px;
      box-sizing: border-box;
      height: 225px;
      background: #ffffff;
      padding: 20px 15px 10px 20px;

      &:active,
      &:hover {
        box-shadow: 0px 0px 20px 0px rgb(206 206 206 / 17%);
        .avatar img {
          transform: scale(1.2);
        }
      }

      &:nth-child(n + 5) {
        margin-top: 2px;
      }

      .info-wrap {
        display: flex;
        align-items: flex-start;
        padding-bottom: 15px;
        border-bottom: 1px dashed #f4f5f9;

        .avatar {
          width: 100px;
          height: 100px;
          margin-right: 25px;
          overflow: hidden;
          border-radius: 50%;
          img {
            transition: all 0.6s ease-in;
          }
        }

        .info {
          flex: 1 1 auto;
          .cname {
            font-size: 14px;
            font-weight: bold;
          }
          .name {
            font-size: 12px;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
          }

          .look-time {
            margin-top: 8px;
            i {
              color: #999;
              font-size: 20px;
              padding-right: 8px;
              vertical-align: middle;
            }
            span:nth-child(2) {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
              vertical-align: middle;
            }
          }

          .xl-tag {
            margin-top: 6px;
            font-size: 12px;
            font-weight: 400;
            color: #666;
            span:nth-child(1) {
              color: #999;
            }
          }
        }
      }

      .tag-wrap {
        padding-top: 15px;

        .row + .row {
          margin-top: 10px;
        }

        .row {
          font-size: 12px;
          color: #666;
          .label {
            color: #999;
          }
        }
      }
    }
  }
}
</style>