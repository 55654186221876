<template>
	<div class="job-home">
		<ori-header />

		<router-view />

		<Nav />
		<cu-card style="margin-bottom:40px"/>
		<page-foot />
	</div>
</template>

<script>
import CuCard from '../../../components/ori-com/CuCard.vue'
import PageFoot from '../../../components/ori-com/PageFoot.vue'
import Nav from './Nav.vue'
export default {
	components: { Nav, PageFoot, CuCard },
	name: 'job-home',
}
</script>

<style lang="scss" scoped>
.job-home {
	background: #f9f9f9;
}
</style>
